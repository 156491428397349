import React, {useState, useEffect} from 'react';
import 'less/AdvancedLists/listItem.less';
import {SortableHandle} from '../../lib/react-sortable-hoc';
import {Checkbox, ProductImage, Button} from '@partssourceinc/react-ui-core';
import {formatMoney} from 'utility';
import {getPartCondition, getPurchaseChoice} from '../../productUtility';
import {AddToCartButton} from 'components/AddToCartButton';
import {useHistory} from 'react-router-dom';
import {useSelector} from 'react-redux';

export default function List({item, onQtyChange, allowEdit, handleRemove, showCheckBox, onSelect}) {
    const DragHandle = SortableHandle(() => <span className="material-icons swap-vert hidden-xs" style={{fontFamily: 'Material Icons'}}>swap_vert</span>);
    const history = useHistory();
    const [option] = useState(item.options[0]);
    const [isSelected, setIsSelected] = useState(item.isSelected);
    const [originalPrice] = useState(option.price);
    const [displayPrice, setDisplayPrice] = useState(option.price);
    const hidePricing = useSelector(state => state.user.settings.hidePricing);
    
    const handleSelection = (e) => {
        onSelect(item, e.checked);
        setIsSelected(e.checked);
    };

    useEffect(() => {
        updateDisplayPrice(item.quantity);
    }, []);

    useEffect(() => {
        setIsSelected(item.isSelected);
    }, [item.isSelected]);

    const goToPDP = () => {
        let detailUrl = `${item.detailUrl}?pspn=${option.psPartNumber || ' '}`;
        if (item.isVariantProduct) detailUrl += `&vrnt=${option.vendorItemNumber}`;
        history.push(detailUrl);
    };

    const handleQtyChange = (qty) => {
        updateDisplayPrice(qty);
        onQtyChange(item.listItemId, qty, displayPrice)
    };

    const updateDisplayPrice = (qty) => {
        let bulkPricingSchedule = option && option.bulkPricingSchedule ? option.bulkPricingSchedule.filter(bp => bp.tierPrice < option.price) : null;
        let displayPrice = originalPrice;
        if (bulkPricingSchedule != null) {
            let bulkPrice = bulkPricingSchedule.filter(x => x.minQtyRange <= qty && (x.maxQtyRange >= qty || x.minQtyRange === x.maxQtyRange));
            if (bulkPrice.length > 0) displayPrice = bulkPrice[0].tierPrice;
        }
        setDisplayPrice(displayPrice);
    };

    return (
        <div className="advanced-list-item">
            <div>
                {allowEdit && !showCheckBox && <DragHandle />}
                {showCheckBox && displayPrice > 0 && <Checkbox className="select-item" label={'Select Item'} checked={isSelected} onChange={handleSelection} />}
            </div>
            <div className="img-container">
                <ProductImage url={option.images && option.images.length > 0 ? option.images[0].thumbnail : item.thumbnailUrl} altText={item.thumbnailAltText} onClick={goToPDP} />
            </div>
            <div className="list-title" onClick={goToPDP}>{option.description || item.description}</div>
            {displayPrice > 0 && <React.Fragment>
                {hidePricing ? <div className="price" style={{height: 0}} /> : <div className="price">
                    {formatMoney(displayPrice)}
                </div>}
                <div>
                    {option.purchaseChoice === 2 && <img src="/images/exchange.svg" alt="Exchange Item" style={{height: '15px', padding: '0px 5px 0 0'}} />}
                    <span className="condition">{getPartCondition(option.lineItemCondition)}</span>
                    <span className="purchase-choice">{getPurchaseChoice(option.purchaseChoice)}</span>
                </div>
            </React.Fragment>}
            <div className="list-item-footer">
                {displayPrice > 0 && <AddToCartButton product={item} option={option} quantity={item.quantity} onQtyChange={handleQtyChange} checkForAcknowldegement={true} />}
                {displayPrice === 0 && <Button title="See Options" secondary={true} className="see-options" onClick={goToPDP}>SEE OPTIONS</Button>}
                {allowEdit && <div className="remove-item" onClick={() => handleRemove(item)}>REMOVE ITEM</div>}
            </div>
        </div>
    );
}
