import React, {useState, useEffect} from 'react';
import {Popup, ProductImage} from '@partssourceinc/react-ui-core';
import 'less/AdvancedLists/confirmationDialog.less';
import {formatMoney} from '../../utility';
import {getPartCondition, getPurchaseChoice} from '../../productUtility';

export default function ConfirmationDialog({products, onConfirm, onCancel, hidePricing}) {

    return (<Popup
        className="adv-list-confirmation-dialog"
        show={true}
        disableClose={false}
        onCancel={onCancel}
        onConfirm={onConfirm}
        cancelText="CONTINUE SHOPPING"
        confirmText="VIEW CART"
    >
        <div className="section-header">{`${products.length} Item(s) Added to Cart`}</div>
        {products.map(ci =>
            (<div className="item-wrapper">
                <span className="item-image">
                    <ProductImage url={ci.thumbnailUrl} style={{width: '120px'}} />
                </span>
                <span className="item-description">
                    <span className="description">{ci.title}</span>
                    <span className="info">
                        <span>Item #:</span><span className="val">{ci.displayPartNumber}</span>
                    </span>
                    <span className="info">
                        <span>Condition:</span>
                        <span className="val">
                            <span className="condition">{getPartCondition(ci.options[0].lineItemCondition)}</span>
                            <span className="purchase-choice">{getPurchaseChoice(ci.options[0].purchaseChoice)}</span>
                        </span>
                    </span>
                    <span className="mobile">
                        {!hidePricing && <span className="item-price">
                            <span className="lbl-bold">
                                {formatMoney(ci.extendedPrice)}
                            </span>
                            <span className="lbl-subtle"> {ci.options[0].uomCode}</span>
                        </span>}
                        <span className="item-quantity">
                            <span>Qty: </span>
                            <span className="lbl-bold">{ci.quantity}</span>
                        </span>
                    </span>
                </span>
                <span className="desktop">
                    {!hidePricing && <span className="item-price">
                        <span className="lbl-bold">
                            {formatMoney(ci.extendedPrice)}
                        </span>
                        <span className="lbl-subtle"> {ci.options[0].uomCode}</span>
                    </span>}
                    <span className="item-quantity">
                        <span>Qty: </span>
                        <span className="lbl-bold">{ci.quantity}</span>
                    </span>
                </span>
            </div>)
        )}
    </Popup>)
}
