import React, {useState, useEffect} from 'react';
import axios from 'axios';
import {useParams} from 'react-router-dom';
import {useSelector, useDispatch} from 'react-redux';
import {useHistory} from 'react-router-dom';
import ListItem from 'pages/AdvancedLists/ListItem';
import {SortableContainer, SortableElement} from '../../lib/react-sortable-hoc';
import _ from 'lodash';
import {debounce, arrayMove, getCookie} from 'utility';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faShare} from '@fortawesome/pro-solid-svg-icons/faShare';
import {Button} from '@partssourceinc/react-ui-core';
import PageMetaData from 'components/PageMetaData';
import ShareListDialog from 'pages/AdvancedLists/ShareListDialog';
import {actionCreators} from 'stores/User';
import {NavLink} from 'react-router-dom';
import {saveUserReduxState} from 'stores/User';
import FacilityDialog from 'components/FacilitySearchDialog';
import {useCallback} from 'react';
import {Popup, ProductImage} from '@partssourceinc/react-ui-core';
import EmptyList from './EmptyList';
import {formatMoney, classNames} from 'utility';
import 'less/AdvancedLists/list.less';
import 'less/flipSwitch.less';
import {getCompanyAndVendorFields, getPartCondition, getPurchaseChoice} from '../../productUtility';
import AssetDialog from 'pages/AdvancedLists/AssetDialog';
import {actionCreators as cartActions} from 'stores/Cart'
import UploadFiles from '../../components/UploadFiles';
import Acknowledgement from 'components/Acknowledgement';
import ConfirmationDialog from 'pages/AdvancedLists/ConfirmationDialog';
import {ExchangeAcknolwedgementDialog} from 'components/Settings/ExchangeAcknolwedgementDialog';

const SortableItem = SortableElement(({value, onQtyChange, allowEdit, handleRemove, showCheckBox, onSelect}) => <ListItem item={value} onQtyChange={onQtyChange} allowEdit={allowEdit} handleRemove={handleRemove} showCheckBox={showCheckBox} onSelect={onSelect} />);

const SortableList = SortableContainer(({items, onQtyChange, allowEdit, handleRemove, showCheckBox, onSelect}) => {
    return ( 
        <div>
            {items.map((value, index) => (
                <SortableItem key={`item-${value.listItemId}-${value.options && value.options.length > 0 ? value.options[0].price : 0}`} index={index} value={value} onQtyChange={onQtyChange} allowEdit={allowEdit} handleRemove={handleRemove} showCheckBox={showCheckBox} onSelect={onSelect} />
            ))}
        </div>
    )
});

export default function List(props) {
    const history = useHistory();

    const facilityId = useSelector(state => state.user.facility.facilityId);
    const facility = useSelector(state => state.user.facility);
    const facilities = useSelector(state => state.user.facilities);
    const user = useSelector(state => state.user);
    const hasFacilityLevelPricing = useSelector(state => state.user.settings.hasFacilityLevelPricing);
    const hasStatOrder = useSelector(state => state.user.settings.statOrder);
    const hidePricing = useSelector(state => state.user.settings.hidePricing);
    const userId = useSelector(state => state.network.tokenInfo.userId);
    let hasAcceptedProp65 = useSelector(state => state.user.settings.hasAcceptedProp65);
    const isListAdmin = useSelector(state => state.user.settings.isListAdmin);

    const [list, setList] = useState({});
    const [items, setItems] = useState([]);
    const [defaultItems, setDefaultItems] = useState([]);
    const [invalidItemCount, setInvalidItemCount] = useState([]);
    const [groupName, setGroupName] = useState([]);
    const [loading, setLoading] = useState(true);
    const [showShareDialog, setShowShareDialog] = useState(false);
    const [showFacilityDialog, setShowFacilityDialog] = useState(false);
    const [allowEdit, setAllowEdit] = useState(false);
    const [showDialog, setShowDialog] = useState(false);
    const [showCheckBox, setShowCheckBox] = useState(false);
    const [showAcknowledgement, setShowAcknowledgement] = useState(false);
    const [acknowledgementItems, setAcknowledgementItems] = useState([]);
    const [isBuyAllItems, setIsBuyAllItems] = useState(false);
    const [showCantOrderMultiRepair, setShowCantOrderMultiRepair] = useState(false);
    let [selectedItems, setSelectedItems] = useState([]);

    // Add To Cart Variables
    const [hasSameRequiredFields, setHasSameRequiredFields] = useState(false);
    const [showAssetDialog, setShowAssetDialog] = useState(false);
    const [showLoading, setShowLoading] = useState(false);
    const [showConfirmation, setShowConfirmation] = useState(false);

    const [toBeRemovedItem, setToBeRemovedItem] = useState(null);
    const [showDeleteItemDialog, setShowDeleteItemDialog] = useState(false);

    const [attachments, setAttachments] = useState([]);

    const [showExchangeAcknowledgement, setShowExchangeAcknowledgement] = useState(false);
    const [exchangePartOptions, setExchangePartOptions] = useState([]);
    const [addToCartFields, setAddToCartFields] = useState([]);

    const {id, wo_id} = useParams();

    const dispatch = useDispatch();
    const {deleteList, deleteListItem, confirmAcknowledgement, getLists} = actionCreators;
    const {loadUserCart} = cartActions;
    const [showSearch, setShowSearch] = useState(false);

    useEffect(() => {
        if (!hasFacilityLevelPricing) {
            if (facilityId > 0) {
                loadData();
            } else {
                setShowFacilityDialog(true);
            }
        }
    }, [id, showFacilityDialog]);

    useEffect(() => {
        if (hasFacilityLevelPricing) {
            if (facilityId > 0) {
                loadData();
            } else {
                setShowFacilityDialog(true);
            }
        }
    }, [id, facilityId]);

    const handleOnDelete = useCallback(() => {
        dispatch(deleteList(id)).then(() => {
            dispatch(getLists());
            history.push('/lists');
        });
    });

    const handleOnDeleteFromList = useCallback(() => {
        dispatch(deleteListItem(id, toBeRemovedItem.listItemId)).then(() => {
            setShowDeleteItemDialog(false);
            loadData();
            dispatch(getLists());
        });
    });

    const handleRemoveItem = (item) => {
        setToBeRemovedItem(item);
        setShowDeleteItemDialog(true);
    };

    const handleSaveAttachments = (attachments) => {

        axios.put(`ShoppingService/api/v1/lists/${id}/files`, attachments).then(x => {
            setAttachments(attachments);
        });

    };

    const loadData = () => {
        setLoading(true);
        const selectedFacilityId = facilityId > 0 ? facilityId : facilities[0].facilityId;
        axios.get(`ShoppingService/api/v1/lists/${selectedFacilityId}/${id}`).then(x => {

            // cheat to keep the product - option 1:1
            const itemsWithMultipleOptions = _.map(x.data.products.filter(x => x.options.length > 1), _.clone);
            x.data.products.forEach(x => x.options = _.take(x.options, 1));
            itemsWithMultipleOptions.forEach(p => {
                for (let i = 1; i < p.options.length; i++) {
                    let item = _.clone(p);
                    item.options = [];
                    item.options.push(p.options[i]);
                    x.data.products.push(item);
                }
            });

            let products = _.orderBy(x.data.products, ['sortOrder'], ['asc']);
            products.filter(p => p.options.length > 0).forEach(p => {
                let selectedItem = {};
                let matches = x.data.list.items.filter(i => i.psPartNumber === p.options[0].psPartNumber);
                if (matches.length > 0 && (matches.length > 1 || matches[0].isVariantProduct)) {
                    selectedItem = matches.find(m => m.vString === p.options[0].vendorItemNumber);                    
                } else {
                    selectedItem = matches.length > 0 ? matches[0] : {};
                }
                
                if (!selectedItem) selectedItem = {id: -1, sortOrder: p.sortOrder};
                p.listItemId = selectedItem.id;
                p.sortOrder = selectedItem.sortOrder;
            });

            products = products.filter(p => p.listItemId !== -1);

            setList(x.data.list);
            setItems(_.orderBy(products, ['sortOrder'], ['asc']));
            setDefaultItems(_.orderBy(products, ['sortOrder'], ['asc']));

            setGroupName(x.data.groupName);
            setAttachments(x.data.list.files);
            setAllowEdit(x.data.groupName === 'mylist' || isListAdmin);
            setInvalidItemCount(x.data.products.filter(d => d.options[0].price === 0).length);
            setLoading(false);
        });
    };

    const handleFilter = (e) => {
        let searchKey = (e.target.value).toLowerCase();
        let items = [...defaultItems].filter(x => (x.title && x.title.toLowerCase().includes(searchKey)) ||
                                                 (x.description && x.description.toLowerCase().includes(searchKey)) ||
                                                 (x.brand && x.brand.toLowerCase().includes(searchKey)) ||
                                                 (x.options && x.options[0] && (x.options[0].price.toString().includes(searchKey) ||
                                                 getPartCondition(x.options[0].lineItemCondition).toLowerCase().includes(searchKey) ||
                                                 getPurchaseChoice(x.options[0].purchaseChoice).toLowerCase().includes(searchKey))));

        setInvalidItemCount(items.filter(x => x.options[0].price === 0).length);
        setItems(_.orderBy(items, ['sortOrder'], ['asc']))
    }

    const onQtyChange = (id, qty, price) => {
        if (allowEdit) {
            axios.post(`ShoppingService/api/v1/lists/list/quantity/${list.ravenId}/${id}/${qty}`);
        }
        list.items.filter(x => x.id === id)[0].quantity = qty;
        let product = items.find(x => x.listItemId === id);
        product.quantity = qty;
        product.options[0].price = price;
        setList(list);
        setItems(items);
        calcSelectedItems(items.filter(x => x.isSelected));
    };

    const onSortEnd = ({oldIndex, newIndex}) => {
        if (oldIndex !== newIndex) {
            let updatedItems = arrayMove(items, oldIndex, newIndex);
            updateSortOrder(updatedItems);
            setItems(updatedItems);
        }
    };

    const updateSortOrder = debounce((updatedItems) => {
        axios.post(`ShoppingService/api/v1/lists/sort/${list.ravenId}`, updatedItems);
    }, 250);

    const onShareListSave = (sharesCount) => {
        list.numberOfShares = list.numberOfShares + sharesCount;
        setShowShareDialog(false);
        loadData();
    };

    const onSelectFacility = (facility) => {
        dispatch(saveUserReduxState({facility: facility}));
        setShowFacilityDialog(false);
    };

    const onCopyList = () => {
        axios.post(`ShoppingService/api/v1/lists/copy/${list.ravenId}`).then(x => {
            dispatch(getLists());
            history.push(`/list/${x.data.id}`);
        });
    };

    const handleBulkItemToggle = (e) => {
        items.forEach(x => {
            x.isSelected = false;
            x.extendedPrice = 0;
        });
        setItems(items);
        setSelectedItems([]);
        setShowCheckBox(e.target.checked);
    };

    const onSelect = (item, isSelected) => {
        items.filter(x => x.listItemId === item.listItemId)[0].isSelected = isSelected;
        setItems(items);
        calcSelectedItems(items.filter(x => x.isSelected));
    };

    const calcSelectedItems = (currentSelection) => {
        if (currentSelection.length > 0 || selectedItems.length > 0) {
            currentSelection.forEach(x => {
                x.extendedPrice = x.quantity * x.options[0].price;
            });
            setSelectedItems(currentSelection);
        }
    };

    const buyAllItems = (e) => {
        setIsBuyAllItems(true);
        addToCart(e, true);
    }

    const addToCart = (e, buyAll) => {
        let requiredFields = [];
        let acknowledgementItems = [];

        if (buyAll) {
            setShowCheckBox(false);

            selectedItems = items.filter(x => x.options[0].price > 0);
            selectedItems.forEach(x => {
                x.isSelected = false;
                x.extendedPrice = x.quantity * x.options[0].price;
            });
        }

        let anyRepairQtyGreaterThanOne = selectedItems.find(s => s.options[0].purchaseChoice === 3 && s.quantity > 1);
        if (anyRepairQtyGreaterThanOne) {
            setShowCantOrderMultiRepair(true);
            return;
        }

        selectedItems.forEach(x => {
            let fields = getCompanyAndVendorFields(false, false, x.options[0],facilityId);
            let option = x.options[0];
            fields.forEach(f => f.psPartNumber = option.psPartNumber);
            requiredFields.push(fields);
            x.fields = fields;
            x.formularyFields = [];
            x.isUrgency = false;
            x.showFormulary = !option.isFormularyOption && option.formularySetting && option.formularySetting.approvalRequired;
            x.psPartNumber = option.psPartNumber;

            const containsChemicals = option.attributes && option.attributes.filter(a => a.name.toLowerCase() === 'prop65r' || a.name.toLowerCase() === 'prop65c');
            if (containsChemicals && containsChemicals.length > 0 && !hasAcceptedProp65) {
                acknowledgementItems.push(x.id);
            }

            if (option.purchaseChoice === 2) {
                const {title, displayPartNumber} = x;
                option.title = title;
                option.displayPartNumber = displayPartNumber;
            }
        });

        if (acknowledgementItems.length > 0) {
            setAcknowledgementItems(acknowledgementItems);
            setShowAcknowledgement(true);
            return;
        }

        if (requiredFields.length > 0) {
            setHasSameRequiredFields(true);
            let hasRequiredFields = false;

            // Make a copy so that a comparison can be ran w/o psPartNumber
            let fieldsToCompare = _.map(_.flatMap(requiredFields[0]), _.clone);
            fieldsToCompare.forEach(x => x.psPartNumber = '');

            requiredFields.forEach(x => {
                if (x.some(x => x.isRequired)) {
                    hasRequiredFields = true;
                }

                let compareArray = _.map(x, _.clone);
                compareArray.forEach(c => c.psPartNumber = '');

                if (!_.isEqual(fieldsToCompare, compareArray)) {
                    setHasSameRequiredFields(false);
                }
            });

            if (hasRequiredFields || hasStatOrder) {
                setShowAssetDialog(true);
                setSelectedItems(selectedItems);
            } else {
                submitCart(requiredFields, selectedItems);
                setSelectedItems(selectedItems);
            }

        } else {
            submitCart([], selectedItems);
        }
    };

    const submitCart = (fields, cItems, isExchangeTermsChecked) => {
        const allOptions = _.flatMap(cItems.map(x => x.options));
        const exchangeParts = allOptions.filter(x => x.purchaseChoice === 2);

        if(exchangeParts.length > 0 && !isExchangeTermsChecked) {
            setExchangePartOptions(exchangeParts);
            setSelectedItems(cItems);
            setAddToCartFields(fields);
            setShowExchangeAcknowledgement(true);
            setShowAssetDialog(false);
            return;
        }

        setShowLoading(true);

        let cartItems = [];

        // Per req, on site service has been left out for now
        cItems.forEach(product => {
            const selectedOption = product.options[0];
            const partImage = selectedOption.images && selectedOption.images[0].image177;
            const fieldValues = fields ? fields.filter(x => x.psPartNumber === selectedOption.psPartNumber) : [];
            const id_ins = getCookie('id_ins');
            let cartItem = {
                UserId: userId,
                CompanyId: facility.facilityId,
                GroupId: facility.groupId,
                RequesterId: userId,
                PhoneNumber: facility.phoneNumber,
                VendorId: (selectedOption && selectedOption.vendorId) || 0,
                ConditionId: (selectedOption && selectedOption.lineItemCondition) || 0,
                purchaseChoiceId: (selectedOption && selectedOption.purchaseChoice) || 0,
                ResearchRedisId: product.researchRedisId,
                Quantity: product.quantity,
                ProductId: product.id,
                OemId: product.manufacturerId,
                partNumber: product.displayPartNumber,
                PartNumberStripped: product.partNumber,
                description: product.description,
                ImagePath: partImage,
                WarrantyId: (selectedOption && selectedOption.lineItemWarranty) || 0,
                Fields: fieldValues.filter(x => !x.formularyField),
                IsQuote: false,
                IsProStockItem: (selectedOption && selectedOption.isProStockItem) || 0,
                UrgencyId: product.isUrgency,
                CustomerOrderKey: wo_id,
                FormularyFields: product.formularyFields,
                Id_ins: id_ins,
                ContractProId: selectedOption && selectedOption.isContractProOption ? selectedOption.formularySetting.contractId : '',
                VariantId: selectedOption.variantId,
                isExchangeTermsChecked: selectedOption.purchaseChoice === 2 ? isExchangeTermsChecked : false
            };

            /*
             if(hasOnSiteService){
                 cartItem.LocationDetail = onSiteService.locationDetail;
                 cartItem.ServiceNeeded = onSiteService.serviceNeeded;
                 cartItem.Model = onSiteService.selectedModel.model;
                 cartItem.ModelId = onSiteService.selectedModel.modelId;
                 cartItem.ServiceType = onSiteService.serviceType.name;
                 cartItem.Category = onSiteService.selectedCategory.name;
                 cartItem.TurnaroundTime = onSiteService.turnaroundTime.name;
                 cartItem.AddOnSiteService = true;
             }*/

            if (cartItem.ImagePath === 'https://assets.partsfinder.com/image/') cartItem.ImagePath = '';

            dataLayer.push({
                event: 'brAddToCart',
                brProductId: product.id,
                brProductSku: product.id,
            }, {
                event: 'addToCart',
                ecommerce: {
                    add: {
                        products: [{
                            name: product.partNumber,
                            id: product.id,
                            price: selectedOption.price,
                            brand: product.manufacturer,
                            category: product.modalityId,
                            variant: `${getPartCondition(selectedOption.lineItemCondition)} -  ${getPurchaseChoice(selectedOption.purchaseChoice)}`,
                            quantity: product.quantity,
                        }],
                        id_ins: id_ins,
                    },
                },
            });
            cartItems.push(cartItem);
        });

        axios.post('/ShoppingService/api/v1/cart/addListItems', cartItems).then((x) => {
            setShowLoading(false);
            setShowAssetDialog(false);
            dispatch(loadUserCart());
            setShowConfirmation(true);
            setShowExchangeAcknowledgement(false);
            setIsBuyAllItems(false);
        });
    };

    const acknowledgeRisk = () => {
        dispatch(confirmAcknowledgement()).then(x => {
            setShowAcknowledgement(false);
            hasAcceptedProp65 = true;
            addToCart();
        });
    };

    const getPrivacyType = () => {
        if (list && list.facilityIds && user.facilities.map(x => x.facilityId).some(v => list.facilityIds.indexOf(parseInt(v)) !== -1)) return 'My Facility';
        else if (user && user.settings && list && list.rootCompanyIds && list.rootCompanyIds.some(x => x = user.settings.rootCompanyId)) return 'Company';
        else if (userId === list.userId) return 'Only Me';
        else return 'PartsSource';
    };

    const searchBoxClasses = {
        'form-control': true,
        'form-control hidden-search-box': true,
        'is-visible': showSearch,
    };

    const handleOnSearchBlur = e => {
        setShowSearch(e.target.value !== '')
    }

    return (
        <div className="advanced-list-page">

            <PageMetaData title="List Details" pageType="other" trackAnalytics={true} />
            {loading && <div className="loading-data"><div className="loader" /></div>}
            <div className="title-row" style={{display: 'flex', justifyContent: 'space-between'}}>
                <div>
                    {groupName === 'mylist' && <span className="page-label chevron link" onClick={() => history.push('/lists?tab=mylist')}>My Lists</span>}
                    {groupName === 'company' && <span className="page-label chevron link" onClick={() => history.push('/lists?tab=company')}>Company Lists</span>}
                    {groupName === 'partssource' && <span className="page-label chevron link" onClick={() => history.push('/lists?tab=partssource')}>PartsSource Lists</span>}
                    <span className="page-label">{list.name}</span>
                    {list.userId === userId && <NavLink className="button-link" to={`/list/${id}/edit`}>
                        <i className="fa fa-pencil" aria-hidden="true" />
                    </NavLink>}
                    {list.isPartsSourceList && (
                        <span className="ps-Logo">PS
                            <span className="ps-tooltipText">
                                <span className="ps-text">
                                    This public list is curated and maintained by PartsSource for
                                    your convenience.
                                </span>
                            </span>
                        </span>
                    )}
                </div>
                <div className="right-side-wrapper">
                    <div className="privacy-wrapper" style={{paddingRight: 30, marginRight: 60, borderRight: '1px solid #777777'}} >
                        <div style={{position: 'relative'}}>
                            <span>Privacy: <span className="privacy-type">{getPrivacyType()}</span></span>
                            <span className="shares" onClick={() => setShowShareDialog(true)}>
                                <FontAwesomeIcon icon={faShare} />
                                <span>{list.numberOfShares}</span>
                            </span>
                        </div>
                    </div>
                    <div className="grid-toolbar" style={{position: 'absolute'}}>
                        <div className={`hidden-search-wrapper ${!showSearch ? 'hidden-search-wrapper--no-margin' : 'hidden-search-wrapper--open'}`}>
                            <input
                                type="search"
                                className={classNames(searchBoxClasses)}
                                onFocus={() => setShowSearch(true)}
                                onBlur={(e) => handleOnSearchBlur(e)}
                                onChange={(e) => handleFilter(e)}
                                placeholder={showSearch && 'Search'}
                                autoComplete="grid-search"
                            />
                            <i className="glyphicon glyphicon-search" />
                        </div>
                    </div>
                </div>

            </div>
            {1 === 2 && <div className="upload-file-wrapper">
                <UploadFiles label={'Upload File'} attachments={attachments} setAttachments={handleSaveAttachments} showRemoval={groupName === 'mylist'} />
            </div>}
            <div className="title-row">
                <div className="privacy-wrapper mobile">
                    <span>Privacy: <span className="privacy-type">{getPrivacyType()}</span></span>
                    <span className="shares" onClick={() => setShowShareDialog(true)}>
                        <FontAwesomeIcon icon={faShare} />
                        <span>{list.numberOfShares}</span>
                    </span>
                </div>
            </div>
            {!isListAdmin && <div className="action-row">
                <span className="share-list" onClick={() => setShowShareDialog(true)}><span>SHARE LIST</span></span>
                {allowEdit && <span className="button-link" onClick={() => setShowDialog(true)} >DELETE LIST</span>}
                {items && items.length > 0 && <span className="cart-buttons">
                    <div className="flipSwitch">
                        <div className="onoffswitch">
                            <input type="checkbox" name="onoffswitch" className="onoffswitch-checkbox" id="myonoffswitch"
                                tabIndex="0" checked={showCheckBox} onClick={handleBulkItemToggle} />
                            <label className="onoffswitch-label" htmlFor="myonoffswitch" />
                        </div>
                    </div>
                    <span className="bulk-selection">Bulk Selection</span>
                    <span className="button">
                        <Button secondary={true} onClick={buyAllItems} loading={isBuyAllItems && showLoading}>{`BUY THIS LIST (${items.length - invalidItemCount} ITEM${items.length - invalidItemCount === 1 ? '' : 'S'})`}</Button>
                    </span>
                </span>}
            </div>}
            {list.userId !== userId && !loading && !isListAdmin && <div className="save-list">
                <Button secondary={true} onClick={onCopyList}>SAVE TO MY LISTS</Button>
            </div>}

            {showCheckBox && selectedItems.length > 0 && <div className="selection-bar">
                {hidePricing ? <span>({selectedItems.length}) {selectedItems.length === 1 ? 'item' : 'items'} selected</span> : <span>({selectedItems.length}) {selectedItems.length === 1 ? 'item' : 'items'} selected
                            totaling to {formatMoney(_.sumBy(selectedItems, x => parseFloat(x.extendedPrice)))}</span>}
                <span className="buttons">
                    <span className={showLoading ? 'add-to-cart disable' : 'add-to-cart'} onClick={addToCart} >ADD TO CART</span>
                </span>
            </div>}

            {items && items.length > 0 ? <div className="item-container">
                <SortableList items={items} onSortEnd={onSortEnd} lockToContainerEdges={true} axis={'xy'} allowEdit={allowEdit} showCheckBox={showCheckBox} onSelect={onSelect}
                    useDragHandle={true} useWindowAsScrollContainer={false} helperClass="sortableHelper" onQtyChange={onQtyChange} handleRemove={handleRemoveItem} />
            </div> :
                <EmptyList />}
            {showShareDialog && <ShareListDialog facility={facility} list={list} onSave={onShareListSave} onCancel={() => setShowShareDialog(false)} />}
            {showFacilityDialog && <FacilityDialog onSubmit={onSelectFacility} onCancel={() => showFacilityDialog(false)} disableClose={!(facilityId > 0)} />}

            <Popup confirmText="Delete List"
                cancelText="Cancel"
                show={showDialog}
                onCancel={() => setShowDialog(false)}
                className="del-list-dialog"
                onConfirm={handleOnDelete}>
                <span className="delete-list-text">Are you sure you want to delete <span className="del-list-name">“{list.name}”</span>?</span>
            </Popup>

            {toBeRemovedItem &&
                <Popup
                    confirmText="Update List"
                    cancelText="Cancel"
                    show={showDeleteItemDialog}
                    className="delete-item-confirmation_popup"
                    onConfirm={handleOnDeleteFromList}
                    onCancel={() => setShowDeleteItemDialog(false)}>
                    <div className="delete-item-confirmation">
                        <div className="delete-item-confirmation_header">
                            <p className="title">Remove Item from List</p>
                        </div>
                        <div className="delete-item-confirmation_item" >
                            <div className="delete-item-confirmation_item_image">
                                <ProductImage
                                    url={toBeRemovedItem.thumbnailUrl}
                                    style={{maxWidth: '100%'}}
                                />
                            </div>

                            <div className="delete-item-confirmation_item_description">
                                <a>{toBeRemovedItem.title}</a>
                                <div style={{marginTop: 10}} className="sub-description">OEM: <strong>{toBeRemovedItem.brand}</strong></div>
                                <div className="sub-description">Item #: <strong>{toBeRemovedItem.partNumber}</strong></div>
                            </div>

                            {!hidePricing && <div className="delete-item-confirmation_item_price">
                                <span className="lbl-bold">
                                    {formatMoney(toBeRemovedItem.outrightListPrice)}
                                </span>
                            </div>}

                            <div className="delete-item-confirmation_item_quantity">
                                <span>Qty: </span>
                                <span className="lbl-bold">{toBeRemovedItem.quantity}</span>
                            </div>
                        </div>
                    </div>
                </Popup>
            }

            {showAcknowledgement && <Acknowledgement acknowledgeRisk={acknowledgeRisk} itemNumbers={acknowledgementItems} onCancel={() => setShowAcknowledgement(false)} />}
            {showAssetDialog && <AssetDialog products={selectedItems} hasSameRequiredFields={hasSameRequiredFields} loading={showLoading} onSubmit={submitCart} onCancel={() => setShowAssetDialog(false)} />}
            {showExchangeAcknowledgement && <ExchangeAcknolwedgementDialog onCancel={() => setShowExchangeAcknowledgement(false)} onConfirm={() => submitCart(addToCartFields, selectedItems, true)} parts={selectedItems.length > 1 ? exchangePartOptions : []} />}
            {showConfirmation && <ConfirmationDialog products={selectedItems} onConfirm={() => history.push('/cart')} onCancel={() => setShowConfirmation(false)} hidePricing={hidePricing} />}

            <Popup
                cancelText="Close"
                hideButtons={true}
                show={showCantOrderMultiRepair}
                onCancel={() => setShowCantOrderMultiRepair(false)}
                className="del-list-dialog">
                <span className="delete-list-text">Multiple quantity repairs must be ordered separately</span>
            </Popup>
        </div>
    );
}
