import React, {useState, useEffect} from 'react';
import {Popup, ProductImage, RadioButton} from '@partssourceinc/react-ui-core';
import {getPartCondition, getPurchaseChoice} from '../../productUtility';
import AssetInformationForm, {AssetFormType} from 'components/AssetInformationForm';
import _ from 'lodash';
import 'less/AdvancedLists/assetDialog.less';

export default function AssetDialog({products, hasSameRequiredFields, onSubmit, onCancel, loading}) {

    const [items, setItems] = useState(products);
    const [assetFields, setAssetFields] = useState(items.map(x => x.fields));
    const [showErrors, setShowErrors] = useState(false);
    const [applyToAll, setApplyToAll] = useState(null);
    const [showApplyToAll, setShowApplyToAll] = useState(items.length > 1);
    const [enableAddToCart, setEnableAddToCart] = useState(false);
    const [displayItems, setDisplayItems] = useState([]);

    useEffect(() => {
        const displayItems = (applyToAll == null || (hasSameRequiredFields && !items.some(x => x.showFormulary) && applyToAll === true)) ? _.take(items, 1) : items;
        let psPartNumberFilter = displayItems.length === 1 && applyToAll ? displayItems[0].options[0].psPartNumber : null;
        let hasMissingFields = _.flatMap(assetFields).filter(x => x && !x.value && x.isRequired && (psPartNumberFilter == null || x.psPartNumber === psPartNumberFilter)).length > 0;
        let enableAddToCart = !(hasMissingFields || (!hasMissingFields && applyToAll == null && items.length > 1) || (applyToAll == null && displayItems.length > 1));

        setEnableAddToCart(enableAddToCart);
        setDisplayItems(displayItems);
    }, [assetFields, applyToAll, items]);

    const onConfirm = () => {
        let fields = getSyncedFields(applyToAll);
        const hasMissingFields = _.flatMap(fields).filter(x => x && !x.value && x.isRequired).length > 0;
        if (hasMissingFields) {
            setShowErrors(true);
            return;
        }

        const items = getSyncedUrgencyFlag(applyToAll);

        onSubmit(_.flatMap(fields), items);
    };

    const onFieldUpdate = (fields) => {
        let updatedFields = assetFields.filter(x => x[0].psPartNumber !== fields[0].psPartNumber);
        updatedFields.push(fields);
        setAssetFields(updatedFields);
    };

    const onUrgencyUpdate = (e, item) => {
        items.filter(x => _.isEqual(x, item))[0].isUrgency = e.checked;
        setItems(items);
    };

    const onApplyAllChange = (e) => {
        let apply = e.target.checked && e.target.value === 'true';
        setApplyToAll(apply);
        const fields = getSyncedFields(apply);
        setAssetFields(fields);

        const items = getSyncedUrgencyFlag(apply);
        setItems(items);

        if (!hasSameRequiredFields) setShowApplyToAll(false);
    };

    const getSyncedFields = (apply) => {
        if (displayItems.length === 1 && apply) {
            let psPartNumber = displayItems[0].options[0].psPartNumber;
            let flattenedFields = _.flatMap(assetFields);
            let fieldsToCopy = flattenedFields.filter(x => x.psPartNumber === psPartNumber);

            fieldsToCopy.forEach(x => {
                flattenedFields.filter(f => f.fieldUid === x.fieldUid).forEach(f => f.value = x.value);
            });
        }

        return assetFields;
    };

    const getSyncedUrgencyFlag = (apply) => {
        if (displayItems.length === 1 && apply) {
            items.forEach(x => x.isUrgency = displayItems[0].isUrgency);
        }
        return items;
    };

    const onFormularyFieldUpdate = (fields, psPartNumber) => {
        items.filter(x => x.psPartNumber === psPartNumber)[0].formularyFields = fields;
        setItems(items);
    };

    return (<Popup
        confirmText="Add To Cart"
        cancelText="Cancel"
        show={true}
        hideButtons={false}
        onCancel={onCancel}
        onConfirm={onConfirm}
        loading={loading}
        disableConfirm={!enableAddToCart}
        className="adv-asset-dialog"
    >
        <h1 className="title">Asset Information</h1>
        {displayItems.map((item, i) => (<div className="product-wrapper">
            <div className="left-column">
                <div className="img-container">
                    <ProductImage url={item.thumbnailUrl} altText={item.thumbnailAltText} />
                </div>
            </div>
            <div className="right-column">
                <div className="title-text">{item.title}</div>
                <div className="detail">
                    <span>Item #:</span>
                    <span className="val">{item.displayPartNumber}</span>
                </div>
                <div className="detail">
                    <span>Condition:</span>
                    <span className="val">
                    {item.options[0].lineItemCondition === 2 && <img src="/images/exchange.svg" alt="Exchange Item" style={{height: '15px', padding: '0px 5px 0 0'}} />}
                        {getPartCondition(item.options[0].lineItemCondition)} - {getPurchaseChoice(item.options[0].purchaseChoice)}
                    </span>
                </div>
                <AssetInformationForm
                    fields={item.fields}
                    formType={AssetFormType.Both}
                    readOnly={false}
                    showRequester={false}
                    hideSaveButton={true}
                    allowMissingFields={false}
                    displayInline={true}
                    hideTitle={true}
                    showErrors={showErrors}
                    priority={item.isUrgency}
                    showFormulary={item.showFormulary}
                    onCheckBoxUpdate={(e) => onUrgencyUpdate(e, item)}
                    oem = {{id: item.manufacturerId, name: item.manufacturer}}
                    onFieldUpdate={onFieldUpdate}
                    onFormularyFieldUpdate={onFormularyFieldUpdate}
                    psPartNumber={item.psPartNumber} />

                {i === 0 && showApplyToAll && <div className="apply-to-all">
                    <span className="asset-text">Apply asset information to all items?</span>
                    <RadioButton id={`option-y`} checked={applyToAll === true} value={true} onChange={onApplyAllChange}
                        name={`option-y`} label="Yes" className="radio-option" />
                    <RadioButton id={`option-n`} checked={applyToAll === false} value={false} onChange={onApplyAllChange}
                        name={`option-n`} label="No" className="radio-option" />
                </div>}
            </div>
        </div>))}
    </Popup>)
}
